<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import appConfig from "@/app.config";

export default {
    page: {
        title: "Contact Users List",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Yönetici Listesi",
            items: [
                {
                text: "Kullanıcılar",
                href: "/"
                },
                {
                text: "Yönetici Listesi",
                active: true
                }
            ]
        };        
    }
}
</script>

<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">       
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
                <div class="btn-group mb-3">
                    <router-link class="btn btn-light dropdown-toggle btn-block" :to="{name:'add-manager'}">
                        <i class="mdi mdi-plus me-1"></i> {{$t('user.UserNew')}}
                    </router-link>
                    <!-- <a class="btn btn-light dropdown-toggle btn-block" href="/user/add-manager/">
                        <i class="mdi mdi-plus me-1"></i> {{$t('user.UserNew')}}
                    </a> -->
                </div>               
                <div class="table-responsive">
                    <table class="table align-middle table-nowrap table-hover">
                    <thead class="table-light">
                        <tr>
                        <th scope="col" style="width: 70px;">#</th>
                        <th scope="col">{{$t('user.Name')}}</th>
                        <th scope="col">{{$t('user.Email')}}</th>
                        <th scope="col">{{$t('user.Status')}}</th>
                        <th scope="col">{{$t('user.Action')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="avatar-xs">                          
                                <span class="avatar-title rounded-circle">S</span>
                                </div>
                            </td>
                            <td>
                                <h5 class="font-size-14 mb-1">
                                    <a href="#" class="text-dark" >
                                        Stephen Rash
                                    </a>
                                <!-- <a :href="'/user/manager-detail/' + list.id" class="text-dark">{{list.name}}</a> -->
                                </h5>
                                <!-- <p class="text-muted mb-0">Biscolata</p> -->
                            </td>
                            <td>stephen@rash.com</td>
                            <td><span class="badge bg-success">{{$t('user.Active')}}</span></td>
                            <td>
                                <ul class="list-inline font-size-20 contact-links mb-0">
                                <li class="list-inline-item px-2">
                                    <a href="#" v-b-tooltip.hover :title="$t('user.Profile')" >
                                        <i class="bx bx-user-circle"></i>
                                    </a>
                                    <!-- <a :href="'/user/manager-detail/' + list.id" v-b-tooltip.hover :title="$t('user.Profile')">
                                        <i class="bx bx-user-circle"></i>
                                    </a> -->
                                </li>
                                <li class="list-inline-item px-2">
                                    <a href="" v-b-tooltip.hover :title="$t('user.Delete')">
                                        <i class="bx bx-trash-alt"></i>
                                    </a>
                                </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="avatar-xs">                          
                                <span class="avatar-title rounded-circle">J</span>
                                </div>
                            </td>
                            <td>
                                <h5 class="font-size-14 mb-1">
                                    <a href="#" class="text-dark" >
                                        Juan Mays
                                    </a>
                                <!-- <a :href="'/user/manager-detail/' + list.id" class="text-dark">{{list.name}}</a> -->
                                </h5>
                                <!-- <p class="text-muted mb-0">Ozmo</p> -->
                            </td>
                            <td>juan@mays.com</td>
                            <td><span class="badge bg-success">{{$t('user.Active')}}</span></td>
                            <td>
                                <ul class="list-inline font-size-20 contact-links mb-0">
                                <li class="list-inline-item px-2">
                                    <a href="#" v-b-tooltip.hover :title="$t('user.Profile')" >
                                        <i class="bx bx-user-circle"></i>
                                    </a>
                                    <!-- <a :href="'/user/manager-detail/' + list.id" v-b-tooltip.hover :title="$t('user.Profile')">
                                        <i class="bx bx-user-circle"></i>
                                    </a> -->
                                </li>
                                <li class="list-inline-item px-2">
                                    <a href="" v-b-tooltip.hover :title="$t('user.Delete')">
                                        <i class="bx bx-trash-alt"></i>
                                    </a>
                                </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                    <ul class="pagination pagination-rounded justify-content-center mt-4">
                        <li class="page-item disabled">
                        <a href="#" class="page-link">
                            <i class="mdi mdi-chevron-left"></i>
                        </a>
                        </li>
                        <li class="page-item">
                        <a href="#" class="page-link">1</a>
                        </li>
                        <li class="page-item active">
                        <a href="#" class="page-link">2</a>
                        </li>
                        <li class="page-item">
                        <a href="#" class="page-link">3</a>
                        </li>
                        <li class="page-item">
                        <a href="#" class="page-link">4</a>
                        </li>
                        <li class="page-item">
                        <a href="#" class="page-link">5</a>
                        </li>
                        <li class="page-item">
                        <a href="#" class="page-link">
                            <i class="mdi mdi-chevron-right"></i>
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
